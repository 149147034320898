<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Tour</h1>
        <button class="btn btn-primary" @click="start()">Comenzar</button>

      </div>
    </div>
  </div>
</template>

<script>
import { useShepherd } from "vue-shepherd";
export default {
  mounted() {
    this.loadPosts()
  },
  data() {
    return {
      posts: []
    }
  },
  methods: {

    start() {
      const tour = useShepherd({
        useModalOverlay: true,
        exitOnEsc: true,
        defaultStepOptions: {
          classes: "shadow-md",
          scrollTo: true,
        },
      });
      //return console.log('refs',el)

      tour.addStep({
        attachTo: {
          element: "#navbarSupportedContent",
          on: "bottom",
        },
        text: `
          <strong>Menu principal</strong>
          <p>En este menu podras navegar por las diferentes secciones de la aplicacion</p>
        `,
        buttons: [
          {
            text: "Siguiente",
            action: tour.next,
          },
        ],
      });

      tour.addStep({
        attachTo: {
          element: "#nav-pdv",
          on: "right",
        },
        text: `
          <strong>Punto de venta</strong>
          <p>En esta seccion podras realizar las ventas de los productos</p>
        `,
        buttons: [
          {
            text: "Siguiente",
            action: tour.next,
          },
        ],
      });

      tour.addStep({
        attachTo: {
          element: "#nav-ticket",
          on: "right",
        },
        text: `
          <strong>Ventas</strong>
          <p>En esta seccion podras gestionar las ventas realizadas</p>
        `,
        buttons: [
          {
            text: "Siguiente",
            action: tour.next,
          },
        ],
      });

      tour.addStep({
        attachTo: {
          element: "#nav-expenses",
          on: "right",
        },
        text: `
          <strong>Gastos</strong>
          <p>En esta seccion podras gestionar los gastos realizados</p>
        `,
        buttons: [
          {
            text: "Siguiente",
            action: tour.next,
          },
        ],
      });

      tour.addStep({
        attachTo: {
          element: "#nav-products",
          on: "right",
        },
        text: `
          <strong>Productos</strong>
          <p>En esta seccion podras gestionar los productos<br>
          Podras agregar, editar y eliminar (productos, categorias, proveedores, cupones, descuentos)</p>
        `,
        buttons: [
          {
            text: "Siguiente",
            action: tour.next,
          },
        ],
      });

      tour.addStep({
        attachTo: {
          element: "#nav-map",
          on: "right",
        },
        text: `
          <strong>VISOR</strong>
          <p>En esta seccion podras visualizar los pedidos en el mapa<br>
          Podras ver los pedidos en tiempo real/modo comandera<br>
          Podras ver los repartidores en el mapa<br>
          Podras crear carrusel de imagenes para mostrar</p>
        `,
        buttons: [
          {
            text: "Siguiente",
            action: tour.next,
          },
        ],
      });

      tour.addStep({
        attachTo: {
          element: "#nav-users",
          on: "bottom",
        },
        text: `
          <strong>USUARIOS</strong>
          <p>En esta seccion podras gestionar los usuarios<br>
          Podras agregar, editar y eliminar (usuarios, roles, permisos)</p>
          Configurar permisos individuales a cada usuario<br>
          </p>
        `,
        buttons: [
          {
            text: "Siguiente",
            action: tour.next,
          },
        ],
      });


      tour.addStep({
        attachTo: {
          element: "#nav-settings",
          on: "bottom",
        },
        text: `
          <strong>Configuracion general</strong>
          <p>En esta seccion podras configurar los datos generales de la empresa<br>
          Podras configurar los datos de la empresa<br>
          Podras configurar tu impresora termica<br>
          Opciones de venta<br>
          </p>
        `,
        buttons: [
          {
            text: "Siguiente",
            action: tour.next,
          },
        ],
      });

      tour.start();
    },
  },
};
</script>